import React from "react"
import MainLayout from "../../layouts"
import { Col, Container, Row } from "react-bootstrap"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import ContactHeading from "../../components/ContactHeading"
import ServiceCards from "../../ServiceCards"

export const pageQuery = graphql`
  query {
    westSacramentoImage: file(relativePath: { eq: "west-sacramento.jpg" }) {
      childImageSharp {
        fluid(quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    leoInVanImage: file(relativePath: { eq: "leo-in-van.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    locksmithVanInCityImage: file(
      relativePath: { eq: "locksmith-van-in-city.jpg" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

function WestSacramentoPage({ data }) {
  return (
    <MainLayout
      title="24 Hour Mobile Locksmith | West Sacramento | (916) 995-0135"
      description="Affordable & fast locksmith services in West Sacramento, CA. 24/7 commercial, residential & auto locksmith service."
      offset
    >
      <ContactHeading
        background={data.westSacramentoImage.childImageSharp.fluid}
      >
        <h1 className="text-center">Swift Locksmith West Sacramento</h1>
        <h2 className="text-center font-weight-light">
          Emergency 24 Hour Locksmith Services
        </h2>
      </ContactHeading>
      <section>
        <ServiceCards />
      </section>
      <Container as="section" className="pb-0">
        <h2 className="text-center">
          Professional, Affordable Locksmith Service in West Sacramento
        </h2>
        <p className="indent">
          Our 24/7 locksmith service provides superlative quality services at
          competitive rates in West Sacramento and the surrounding areas. Our
          team of qualified professionals is equipped to handle all your home,
          car, and office locksmith needs. Our staff of professionals have the
          skills and expertise to service all your lock-related needs and can
          help you out regardless of your location or the hour of day. Since we
          are mobile, we are able to reach you in a timely manner and be of aid
          to you right away.
        </p>
        <p className="indent mb-0">
          If you are wondering why you should choose us as your locksmith
          service providers, the answer is really quite simple. The reason to
          call upon our help is because we provide superior quality locksmith
          services at really affordable prices 24/7. We know that choosing
          expert locksmiths locally, is not always so easy to do, and finding
          good locksmith technicians with low service fees and rates is even
          more difficult to do. Because of our reasonable rates and quality
          service, we are truly your best security option. You don’t have to
          take our word for it, try us out and let us prove our worth to you.
          Give us a call, trust us- you won’t regret it!
        </p>
      </Container>
      <Container as="section">
        <h2 className="text-center">Our Swift Locksmith Team</h2>
        <p className="indent mb-0">
          Our team at Swift Locksmith is dedicated to helping you out. Because
          of our strong desire to provide you with the best possible service, we
          hire only certified locksmith technicians who have lots of working
          experience and have the appropriate training and licencing to qualify
          them to handle your security needs. Each of our technicians are fully
          covered by insurance just in case of a potential mishap. Our staff of
          friendly, professional locksmiths is spread throughout West Sacramento
          and are qualified to serve you quickly and professionally, anytime and
          anywhere.
        </p>
      </Container>
      <section>
        <div
          className="background-content text-white"
          style={{ backgroundColor: "#2c485b" }}
        >
          <Container>
            <h2 className="text-center">
              Services We Offer in West Sacramento
            </h2>
            <h3 className="text-primary mb-2">Emergency Locksmith Services</h3>
            <Row>
              <Col xs={12} md={6} lg={5}>
                <ul className="mb-0">
                  <li>Emergency Rekey Services</li>
                  <li>Car, House, Business Lockouts</li>
                  <li>Emergency Lock Repair Services</li>
                  <li>Bedroom, Storage, Garage Lockouts</li>
                </ul>
              </Col>
              <Col xs={12} md={6}>
                <ul>
                  <li>Mail Box Lockouts</li>
                  <li>Replacement of Lost Car Keys</li>
                  <li>Broken Key Extraction</li>
                  <li>Duplicate Keys</li>
                </ul>
              </Col>
            </Row>
            <h3 className="text-primary mb-2">
              Residential Locksmith Services
            </h3>
            <Row>
              <Col xs={12} md={6} lg={5} className="mb-0">
                <ul className="mb-0">
                  <li>24/7 Emergency Lockout</li>
                  <li>Door Knob Lock Installation</li>
                  <li>Whole House Rekey</li>
                  <li>Lock Repair & Maintenance</li>
                </ul>
              </Col>
              <Col xs={12} md={6}>
                <ul>
                  <li>High Security Locks</li>
                  <li>Duplicate House Keys</li>
                  <li>On-Site Key Extraction</li>
                  <li>Master Key Systems</li>
                </ul>
              </Col>
            </Row>
            <h3 className="text-primary mb-2">Automotive Locksmith Services</h3>
            <Row>
              <Col xs={12} md={6} lg={5} className="mb-0">
                <ul className="mb-0">
                  <li>24/7 Emergency Lockout</li>
                  <li>Broken Key Extraction</li>
                  <li>Ignition Rekey</li>
                  <li>Laser Cut Keys</li>
                </ul>
              </Col>
              <Col xs={12} md={6}>
                <ul>
                  <li>Chip Keys Cut Services</li>
                  <li>Remote Programming</li>
                  <li>Lost Car Key Replacement</li>
                  <li>Replace Keyless Entry Remotes</li>
                </ul>
              </Col>
            </Row>
            <h3 className="text-primary mb-2">Commercial Locksmith Services</h3>
            <Row>
              <Col xs={12} md={6} lg={5} className="mb-0">
                <ul className="mb-0">
                  <li>24/7 Emergency Lockout</li>
                  <li>Business Rekey</li>
                  <li>Locks & Exit Device Repairs</li>
                  <li>Master Key System Installation</li>
                </ul>
              </Col>
              <Col xs={12} md={6}>
                <ul className="mb-0">
                  <li>Keyless Access System</li>
                  <li>Aluminum Door Lock Alignment & Installation</li>
                  <li>On-site Key Duplication</li>
                  <li>Deadbolts, High Security Deadbolts</li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
      <Container as="section" className="pb-0 pb-sm-5">
        <h2>Our Team of Locksmith Technicians</h2>
        <Row>
          <Col xs={12} lg={{ span: 8, order: 2 }} xl={7}>
            <p className="indent">
              It’s absolutely imperative that you call upon the services of
              professional locksmiths to service your locksmith needs. A good
              locksmith is someone with strong mechanical knowledge, superb hand
              eye coordination, and good people skills. Our locksmiths have all
              those things, plus they’ve undergone extensive training to help
              them further develop their natural abilities as well as expose
              them to all the latest tools, tricks, and security systems that
              are on the market today. Our technicians are skillful individuals
              who have years of hands-on experience in dealing with all things
              lock-related. When you call upon Swift Locksmith service, you know
              that you are in capable hands.
            </p>
          </Col>
          <Col xs={12} lg={4} xl={5} className="col-sm-pad">
            <Img
              fluid={data.leoInVanImage.childImageSharp.fluid}
              alt="leo-in-car"
            />
          </Col>
        </Row>
      </Container>
      <Container as="section" className="pb-0 pb-sm-5 mb-md-5">
        <h2>Why Choose Our Service?</h2>
        <Row>
          <Col xs={12} lg={{ span: 8, order: 2 }} xl={7}>
            <p className="indent">
              When it comes to selecting the perfect locksmith to meet your
              individual locksmith needs, you’d be wise to call upon the
              services of our team of friendly and proficient locksmith
              technicians. For your safety and security, our expert locksmiths
              are licensed to service all things lock, key, and security
              related. Additionally, each one of our technicians is completely
              insured “just in case” and will take full responsibility for their
              services. Best of all, because our locksmiths are dispersed
              throughout West Sacramento and the surrounding cities, one of our
              mobile locksmiths will be able to reach you promptly wherever you
              are and help you out with all your locksmith-related needs. Make
              the sensible choice by contacting us today!
            </p>
          </Col>
          <Col xs={12} lg={4} xl={5} className="col-sm-pad">
            <Img
              fluid={data.locksmithVanInCityImage.childImageSharp.fluid}
              alt="locksmith-van-in-city"
            />
          </Col>
        </Row>
      </Container>
    </MainLayout>
  )
}

export default WestSacramentoPage
